import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {TabBarService} from '@services/tab-bar.service';
import {AccessControl, isValidPage, TabPageInfo} from '@pages/page-info';
import {MenuController, ModalController} from '@ionic/angular';
import {IAuthService} from '@services/i-auth.service';
import {gearPageInfo} from '@pages/gear/gear.page.info';
import {loginPageInfo} from '@pages/auth/login/login.page.info';
import {logoutPageInfo} from '@pages/auth/login/logout.page.info';
import {helpPageInfo} from '@pages/help/help.page.info';
import {notificationsPageInfo} from '@pages/notifications/notifications.page.info';
import {ridersPageInfo} from '@pages/riders/riders.page.info';
import {spotsPageInfo} from '@pages/spots/spots.page.info';
import {UntilDestroy} from '@ngneat/until-destroy';
import {settingsPageInfo} from '@pages/settings/settings.page.info';
import {LoginComponent} from '@components/login/login.component';
import {wallPageInfo} from '@pages/wall/wall.page.info';
import {mePageInfo} from '@pages/riders/me/me.page.info';
import {aboutPageInfo} from '@pages/about/about.page.info';
import {newsPageInfo} from '@pages/news/news.page.info';
import {ActionMenuService} from "@services/action-menu.service";
import {TABS_ROOT} from "@core/routing/pages-list";

const PAGES: TabPageInfo[] = [
  wallPageInfo,
  mePageInfo,
  spotsPageInfo,
  ridersPageInfo,
  gearPageInfo,
  // gearBrandsPageInfo,
  notificationsPageInfo,
  //forecastPageInfo,
  settingsPageInfo,
  newsPageInfo,
  helpPageInfo,
  logoutPageInfo,
  loginPageInfo,
  aboutPageInfo
];

export function getFullPath(page: TabPageInfo) {
  return '/' + TABS_ROOT + '/' + page.path;
}

export const APP_PAGES: (TabPageInfo & { fullPath: string })[] = PAGES.map((page: TabPageInfo) => {
  return {
    fullPath: getFullPath(page),
    ...page
  };
});

export const DEFAULT_ANONYMOUS_PAGE = aboutPageInfo;
export const DEFAULT_APP_PAGE = spotsPageInfo;
export const DEFAULT_LOGGED_PAGE = wallPageInfo;

@UntilDestroy()
@Component({
  selector: 'zef-tabs-page',
  templateUrl: 'tabs.page.html',
  styleUrls: ['tabs.page.scss']
})
export class TabsPage implements OnInit, AfterViewInit {
  AccessControl = AccessControl;
  loginPageInfo = loginPageInfo;
  logoutPageInfo = logoutPageInfo;
  notificationsPageInfo = notificationsPageInfo;

  readonly MAX_TABS = 5;

  get authorizedPages() {
    return PAGES.filter(p => isValidPage(p, this.auth.isLoggedIn));
  }

  get tabBarPages(): TabPageInfo[] {
    return this.authorizedPages
      .slice(0, this.MAX_TABS);
  }

  get showMenu() {
    return this.authorizedPages.length > this.MAX_TABS;
  }

  @ViewChild('tabBar', {read: ElementRef, static: false}) private tabBarRef: ElementRef;
  @ViewChild('addMenu', {read: ElementRef, static: false}) public addMenu: ElementRef<HTMLIonFabListElement>;

  showBackdrop = false;

  constructor(
    public tabBarService: TabBarService,
    private menu: MenuController,
    public auth: IAuthService,
    public modalController: ModalController,
    public actionMenu: ActionMenuService
  ) {
  }

  ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.tabBarService.init(this.tabBarRef);
  }

  async openMenu() {
    await this.menu.enable(true);
    await this.menu.open();
  }

  async openLogin() {
    const modal = await this.modalController.create({
      component: LoginComponent,
      componentProps: {}
    });
    return await modal.present();
  }

  get menuPages(): (TabPageInfo & { fullPath: string })[] {
    return APP_PAGES.filter(p => isValidPage(p, this.auth.isLoggedIn)).slice(
      this.tabBarService.isDesktop ? 0 : this.MAX_TABS
    );
  }

  onSplitPaneChange($event: CustomEvent) {
    this.tabBarService.isDesktop = $event.detail.visible;
  }

  toggleBackdrop() {
    this.showBackdrop = !this.addMenu.nativeElement.activated;
    return true;
  }

  hideBackdrop() {
    this.showBackdrop = false;
    return true;
  }
}
