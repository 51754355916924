import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EntityLink } from '@model/entity-link';


@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  refreshQueue: Subject<EntityLink> = new Subject<EntityLink>();

  constructor(
  ) {
  }

  /**
   * Can be just an entity type, or a more precise link
   * @param entityLink
   */
  sendRefresh(entityLink: EntityLink) {
    this.refreshQueue.next(entityLink);
  }

  subscribeToRefresh(): Observable<EntityLink> {
    return this.refreshQueue.asObservable();
  }
}
