import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import {
  getCenterPoint,
  getStartPoint,
  GpxData,
  gpxToGeoJson,
  gpxToLeaflet,
  LeafletGpx,
  leafletGpxGetData
} from '@model/place.utils';
import { FormatType } from '@pipes/format-time.pipe';
import { FieldLabelPipe } from '@pipes/fields/field-label.pipe';
import { Point } from 'geojson';
import { Errors, Queries } from '@api/queries';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, tap } from 'rxjs/operators';
import { EditSessionComponent, GpxInput } from '@components/session/edit-session/edit-session/edit-session.component';
import { Id } from '@model/entity';
import { FeedbackService } from '@services/feedback.service';
import { TranslateService } from '@ngx-translate/core';
import { filterNullish } from '@utils/rxjs.utils';
import { EditSpotComponent } from '@components/spot/edit-spot/edit-spot.component';
import { DismissRole } from '@utils/dismissRole';
import { EditSpotLaunchComponent } from '@components/spot-launch/edit-spot-launch/edit-spot-launch.component';
import { TranslateConfigService } from '@services/translate-config.service';
import { Commands } from '@services/meteor/commands';
import { FindSpotAndSessionResponse } from '@api/queries/track-log.queries';

@UntilDestroy()
@Component({
  selector: 'zef-add-track-log',
  templateUrl: './add-track-log.component.html',
  styleUrls: ['./add-track-log.component.scss']
})
export class AddTrackLogComponent implements OnInit {
  @Input() inputGpx: string;

  disabledButton: boolean = true;
  isMobile: boolean;
  file: {
    fileName: string,
    content: string,
    leafletData: LeafletGpx,
    data: GpxData & {
      startPoint: Point,
      centerPoint: Point,
    },
  };
  JSON = JSON;
  FormatType = FormatType;
  titles: {
    to?: string;
    from?: string,
    distanceKm?: string
    avgSpeedKnot?: string,
    maxSpeedKnot?: string,
  } = {};
  accept: string = '.gpx';

  url: string;
  private loading: HTMLIonLoadingElement;

  constructor(
    private modalController: ModalController,
    private queries: Queries,
    platform: Platform,
    fieldLabelPipe: FieldLabelPipe,
    private feedback: FeedbackService,
    private translate: TranslateService,
    public translateConfig: TranslateConfigService,
    private alertController: AlertController,
    private commands: Commands,
    private loadingCtrl: LoadingController,
  ) {
    this.isMobile = !platform.is('desktop');

    this.titles.from = fieldLabelPipe.getFieldTitle('fromInUserTZ', null);
    this.titles.to = fieldLabelPipe.getFieldTitle('toInUserTZ', null);
    this.titles.distanceKm = fieldLabelPipe.getFieldTitle('distanceKm', null);
    this.titles.avgSpeedKnot = fieldLabelPipe.getFieldTitle('avgSpeedKnot', null);
    this.titles.maxSpeedKnot = fieldLabelPipe.getFieldTitle('maxSpeedKnot', null);
  }

  async ngOnInit() {
    if (this.inputGpx !== undefined) {
      // Component was opened with input GPX
      await this.showLoading();
      await this.loadFileContent(this.inputGpx, 'shared.gpx');
    }
  }

  async close() {
    await this.modalController.dismiss();
  }

  async submit() {
    this.disabledButton = true;
    this.queries.findSpotAndSessions.call$({
      ...this.file.data
    }).pipe(
      catchError(async (error: { reason: Meteor.Error }) => {

        if (error.reason?.error === Errors.NOT_FOUND) {
          // No spot found for this center position => propose to create one

          await this.proposeToCreateNewSpot();
        } else {
          await this.feedback.meteorError(error.reason);
        }
        this.disabledButton = false;
        return undefined;
      }),
      filterNullish(),
      tap(async ({ spot, spotLaunch, sessions, spotDay }: FindSpotAndSessionResponse) => {

        if (spotLaunch === undefined) {
          // Spot launch not recognized, invite to create one

          const roles = {
            OK: 'ok',
            CANCEL: 'cancel'
          };

          const alert = await this.alertController.create({
            header: this.translate.instant('TRACK_LOGS.SPOT_LAUNCH_NOT_FOUND.HEADER', { spot: spot.name }),
            message: this.translate.instant('TRACK_LOGS.SPOT_LAUNCH_NOT_FOUND.MESSAGE', { spot: spot.name }).replace(/\n/g, '<br/>'),
            buttons: [
              {
                text: this.translate.instant('TRACK_LOGS.SPOT_LAUNCH_NOT_FOUND.CANCEL'),
                role: roles.CANCEL,
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: () => {
                }
              }, {
                text: this.translate.instant('TRACK_LOGS.SPOT_LAUNCH_NOT_FOUND.OK'),
                role: roles.OK,
                id: 'confirm-button',
                handler: () => {
                }
              }
            ]
          });

          await alert.present();

          const { role } = await alert.onDidDismiss();

          if (role === roles.OK) {
            const modal = await this.modalController.create({
              component: EditSpotLaunchComponent,
              cssClass: 'fullscreen',
              componentProps: <Partial<EditSpotLaunchComponent>> {
                spot,
                // Default spot launch on starting position from the track log
                defaultLocation: {
                  type: 'Point',
                  // Some GPX can inclue altitude (third coordinate), we don't care about it
                  coordinates: this.file.data.startPoint.coordinates.slice(0, 2)
                }
              }
            });

            await modal.present();

            // When the spot is created, we want to re-try the upload of the tracklog
            const { role } = await modal.onDidDismiss();
            if (role === DismissRole.CREATED) {
              // Re-attempt the upload
              await this.submit();

              this.disabledButton = false;
              // Stop here to avoid double creation of session!
              return;
            }

            // If the spot launch was not created, no worries, continue to session creation
          }

        }

        const existingSession = (sessions.length === 1) ? sessions[0] : undefined;

        const componentProps: {
          spotId: Id,
          spotDayDate: Date,
          sessionId?: Id,
          spotLaunchId?: Id,
          gpxInput: GpxInput,
        } = {
          spotId: spot._id,
          spotDayDate: spotDay?.startDate,
          spotLaunchId: spotLaunch?._id,
          gpxInput: {
            ...this.file.data,
            fileName: this.file.fileName,
            gpxData: this.file.content
          }
        };
        let message: string;
        if (existingSession) {
          componentProps.sessionId = existingSession._id;
          message = this.translate.instant('TRACK_LOGS.FOUND_SESSION');
        } else {
          message = this.translate.instant('TRACK_LOGS.NEW_SESSION');
        }

        await this.feedback.toast(message, 5_000);

        const modal = await this.modalController.create({
          component: EditSessionComponent,
          componentProps
        });
        await this.close();
        this.disabledButton = false;
        await modal.present();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  private async proposeToCreateNewSpot() {
    const roles = {
      OK: 'ok',
      CANCEL: 'cancel'
    };

    const alert = await this.alertController.create({
      header: this.translate.instant('TRACK_LOGS.SPOT_NOT_FOUND.HEADER'),
      message: this.translate.instant('TRACK_LOGS.SPOT_NOT_FOUND.MESSAGE').replace(/\n/g, '<br/>'),
      buttons: [
        {
          text: this.translate.instant('SHARED.CANCEL'),
          role: roles.CANCEL,
          id: 'cancel-button',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('SHARED.OK'),
          role: roles.OK,
          id: 'confirm-button'
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role === roles.OK) {
      const modal = await this.modalController.create({
        component: EditSpotComponent,
        cssClass: 'fullscreen',
        componentProps: <Partial<EditSpotComponent>>{
          defaultCenterAndZoom: {
            // Center to the center of the tracklog
            center: this.file.data.centerPoint.coordinates,
            zoom: 14
          }
        }
      });

      // When the spot is created, we want to re-try the upload of the tracklog
      modal.onDidDismiss().then(
        async ({ role }: { role: DismissRole }) => {
          if (role === DismissRole.CREATED) {
            // Re-attempt the upload!
            await this.submit();
          }
        }
      );

      await modal.present();
    }
  }

  async onFileDropped($event: NgxDropzoneChangeEvent) {
    await this.showLoading();

    this.file = undefined;

    const fileObject = $event.addedFiles[0];
    const fileName = fileObject.name;
    const content = await this.readFile(fileObject);

    await this.loadFileContent(content, fileName);
  }

  private async showLoading() {
    this.loading = await this.loadingCtrl.create({
      message: this.translate.instant('TRACK_LOGS.ADD.LOADING')
    });

    await this.loading.present();
  }

  private async hideLoading() {
    this.disabledButton = false;
    await this.loading?.dismiss();
  }

  async downloadFromUrl() {
    if ((this.url?.length ?? 0) === 0) {
      return;
    }

    await this.showLoading();

    const fileName = this.url.split('/').pop()

    // Direct download will fail because of CORS. Workaround: use backend as tunnel
    /*
    this.http.get<string>(this.url).pipe(
      map(response => response),
      tap(content => this.loadFileContent(content, fileName))
    ).subscribe()
     */

    this.commands.downloadTrackLog.call$({url: this.url}).pipe(
      tap(content => this.loadFileContent(content, fileName))
    ).subscribe()
  }

  private async loadFileContent(content: string, fileName: string) {
    // FIXME should do some sanity checks?

    const leafletData = gpxToLeaflet(content);

    const data = leafletGpxGetData(leafletData);
    // TODO could probably extract center position and start position directly from Leaflet object, without the need to parse into GeoJson
    const geoJson = gpxToGeoJson(content);
    const centerPoint = getCenterPoint(geoJson);
    const startPoint = getStartPoint(geoJson);
    this.file = {
      fileName,
      content,
      leafletData,
      data: {
        ...data,
        startPoint,
        centerPoint
      }
    };

    await this.hideLoading();
  }

  async readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => resolve(fr.result as string);
      fr.onerror = reject;
      fr.readAsText(file);
    });
  }
}
