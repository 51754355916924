import { HostListener, Injectable } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SendIntent } from 'send-intent';
import { Filesystem } from '@capacitor/filesystem';
import { AddTrackLogComponent } from '@components/tracklog/add-tracklog/add-track-log.component';
import { FeedbackService } from '@services/feedback.service';
import { IAuthService } from '@services/i-auth.service';

@Injectable({
  providedIn: 'root'
})
export class IntentHandlerService {

  constructor(
    private feedbackService: FeedbackService,
    private modalController: ModalController,
    private authService: IAuthService,
    private alertController: AlertController
  ) {
  }

  @HostListener('window:sendIntentReceived', [])
  async checkIntent(): Promise<boolean> {
    const subTitle = 'Import GPX';
    try {

      const result: any = await SendIntent.checkSendIntentReceived();

      if (result.url && result.type === 'text/xml') {
        if (!this.authService.isLoggedIn) {
          // FIXME translate
          await this.feedbackService.textError({ subTitle, errorMsg: 'You need to be logged in to import a GPX file' });
          return false;
        }
        let resultUrl = decodeURIComponent(result.url);
        const content = await Filesystem.readFile({ path: resultUrl });
        const data = atob(content.data);

        await this.uploadGPX(data);
        return true;
      }
    } catch (error) {
      return false;
    } finally {
      // FIXME check if really needed or not
      //SendIntent.finish();
    }
  }

  async uploadGPX(inputGpxData: string) {
    const componentProps: Partial<AddTrackLogComponent> = {
      inputGpx: inputGpxData
    };
    const modal = await this.modalController.create({
      component: AddTrackLogComponent,
      componentProps: componentProps
    });
    return await modal.present();
  }
}