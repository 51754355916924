import {Component, Input, OnInit} from '@angular/core';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {IForm, NumberFormField} from '@utils/form';
import { WindDegToCompassPipe } from '@pipes/deg-to-compass.pipe';

@Component({
  selector: 'zef-angle-input',
  templateUrl: './angle-input.component.html',
  styleUrls: ['./angle-input.component.scss']
})
export class AngleInputComponent implements OnInit {
  @Input() field: NumberFormField;
  @Input() form: IForm;

  step = 10;
  max = 360 - this.step;

  FieldLabelType = FieldLabelType;

  constructor(private degToCompass: WindDegToCompassPipe) {
  }

  public get style(): string {
    return this.degToCompass.getArrowStyle(this.field.value);
  }

  ngOnInit() {
  }

  pinFormatter(value: number) {
    return `${value}°`
  }

}
