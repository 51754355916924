<form [formGroup]='form.group'>
    <ion-item>
        <ion-label class='input'>{{ field | fieldLabel : FieldLabelType.TITLE }}</ion-label>
        <ion-textarea formControlName='{{field.name}}'
                      placeholder='{{ field | fieldLabel : FieldLabelType.PLACEHOLDER }}'
                      [autoGrow]="true"
                      [required]='field.isRequired'></ion-textarea>
    </ion-item>
    <zef-error-line [field]='field'></zef-error-line>
</form>
