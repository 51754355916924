import { Pipe, PipeTransform } from '@angular/core';
import { degToCompass } from '@model/weather-utils';

@Pipe({
  name: 'windDegToCompass'
})
export class WindDegToCompassPipe implements PipeTransform {

  transform(value: number): string {
    return degToCompass(value); // TODO translate
  }

  public getArrowStyle(value: number): string {
    // Make the icon rotate according to the wind direction, but put it upside down because we want to show FROM
    return `transform: rotate(${value + 180}deg);`
  }

}