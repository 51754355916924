import { Component, OnInit } from '@angular/core';
import { AccessControl } from '@pages/page-info';
import { TabBarService } from '@services/tab-bar.service';
import { ModalController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateConfigService } from '@services/translate-config.service';
import { IAuthService } from '@services/i-auth.service';
import { Router } from '@angular/router';
import { DEFAULT_ANONYMOUS_PAGE, DEFAULT_LOGGED_PAGE, getFullPath } from '@pages/tabs/tabs.page';
import { IntentHandlerService } from '@services/intent-handler.service';

@Component({
  selector: 'zef-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  AccessControl = AccessControl;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateConfig: TranslateConfigService,
    public auth: IAuthService,
    public modalController: ModalController,
    public router: Router,
    public navController: NavController,
    private intentHandlerService: IntentHandlerService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    await this.translateConfig.setLoggedOutLanguage();
    await this.platform.ready();

    await this.intentHandlerService.checkIntent();

    this.statusBar.styleDefault();
    this.splashScreen.hide();

    // Support back button navigation
    this.platform.backButton.subscribeWithPriority(999, async () => {
      const modal = await this.modalController.getTop();
      // If there is a modal, simply close it. Otherwise, navigate back in history
      if (modal) {
        await this.modalController.dismiss();
        return;
      } else {
        if (this.isAtRootPage()) {
          navigator['app'].exitApp();
        } else {
          this.navController.back();
        }
      }
    });
  }

  private isAtRootPage() {
    return this.router.url === getFullPath(DEFAULT_ANONYMOUS_PAGE) || this.router.url === getFullPath(DEFAULT_LOGGED_PAGE);
  }
}
