<form [formGroup]='form.group'>
    <ion-item class="transparent">
        <ion-label class='input'>{{ field | fieldLabel : FieldLabelType.TITLE }}</ion-label>
        <span class="orientation">
            <ng-icon name="mat-north" [style]="style" size="1.5em"></ng-icon>
          <br/>
              {{ field.value | windDegToCompass }}
        </span>
        <ion-range
          formControlName='{{ field.name }}'
          [min]="0"
          [max]="max"
          [snaps]="true"
          [pin]="true"
          [ticks]="true"
          [step]="step"
          [pinFormatter]="pinFormatter"
        ></ion-range>
    </ion-item>
    <zef-error-line [field]='field'></zef-error-line>
</form>