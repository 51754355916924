import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-draw';
import L, {
  Control,
  DivIcon,
  DrawEvents,
  DrawOptions,
  featureGroup,
  FeatureGroup,
  GeoJSON,
  geoJSON,
  LatLngExpression,
  Layer,
  LayerGroup,
  Map,
  MapOptions,
  marker,
  Marker,
  tileLayer
} from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet-fullscreen';
import 'leaflet.fullscreen';
import {Location} from '@model/place';
import {TranslateService} from '@ngx-translate/core';
import {centerLatLng} from '@model/place.utils';
import {GeoJsonObject, Position} from 'geojson';
import {Platform} from "@ionic/angular";

export interface CenterAndZoom {
  center: Position,
  zoom: number
}

/**
 * Should be abstract but then the compilation of the HTML component fails because
 * the component is not declared in a module
 */
@Component({
  selector: 'zef-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input() options: {
    heightPx?: number,
    scrollWheelZoom?: boolean,
    fullScreen?: boolean
  } = {};
  @Input() heightPx?: number;
  @Input() scrollWheelZoom = false; // To avoid zoom by mistake
  @Input() edit = false;
  @Output() edited = new EventEmitter<Layer[]>();

  map: Map;
  leafletOptions: MapOptions;
  leafletDrawOptions: Control.DrawConstructorOptions;
  drawnItems: FeatureGroup = featureGroup();
  drawControl: Control.Draw;

  private currentPolygonOptions: DrawOptions.PolygonOptions | false = false;
  private polygonOptions: DrawOptions.PolygonOptions;
  private currentMarkerOptions: DrawOptions.MarkerOptions | false = false;
  private pointOptions: DrawOptions.MarkerOptions;
  cluster: Layer[] = [];
  private readonly isMobile: boolean;

  constructor(
    protected translate: TranslateService,
    platform: Platform,
  ) {
    this.isMobile = !platform.is('desktop');
  }

  initCreatePolygon(options: DrawOptions.PolygonOptions) {
    this.polygonOptions = options;
  }

  initCreatePoint(options: DrawOptions.MarkerOptions) {
    this.pointOptions = options;
  }

  toggleDrawCreatePolygon(active: boolean) {
    this.currentPolygonOptions = active ? this.polygonOptions : false;
    this.refreshDrawControl();
  }

  toggleDrawCreatePoint(active: boolean) {
    this.currentMarkerOptions = active ? this.pointOptions : false;
    this.refreshDrawControl();
  }

  ngOnInit() {
    this.leafletDrawOptions = {
      position: 'topright',
      draw: {
        circle: false,
        marker: this.currentMarkerOptions,
        polyline: false,
        circlemarker: false,
        rectangle: false,
        polygon: this.currentPolygonOptions
      },
      edit: {
        featureGroup: this.drawnItems
      }
    };

    this.leafletOptions = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        })
      ],
      zoom: 2,
      scrollWheelZoom: this.options.scrollWheelZoom ?? false,
      zoomControl: !this.isMobile
    };
  }

  protected addPoint(location: Location, addTo: Map | LayerGroup = this.map, icon ?: DivIcon): Marker {
    const latLng = centerLatLng(location);
    const options = icon ? {icon} : {};
    const markerObject = marker(latLng, options);
    this.cluster.push(markerObject);
    return markerObject;
  }

  protected addPolygon(location: GeoJsonObject, addTo: Map | LayerGroup = this.map): GeoJSON {
    const geoJSONObject = geoJSON(location);
    geoJSONObject.addTo(addTo);
    return geoJSONObject;
  }

  initMap(map: L.Map) {
    this.map = map;

    this.map.addLayer(this.drawnItems);


    this.initFullScreenControls();

    this.onMapReady();
    this.refreshDrawControl();
  }

  // ------------------------------- Fullscreen management

  private get isFullScreenAllowed(): boolean {
    return this.options.fullScreen ?? false;
  }

  /**
   * Initialize the full screen controls
   * @private
   */
  private initFullScreenControls() {
    if (!this.isFullScreenAllowed) return;

    // On mobile, just tap to enter full screen (exit button is then added)
    if (this.isMobile) {
      // `fullscreenchange` Event that's fired when entering or exiting fullscreen.
      this.map.on('fullscreenchange', () => {
        if (this.map.isFullscreen()) {
          // just entered fullscreen
          this.addFullscreenButton();
          this.map.dragging.enable();
        } else {
          // just exited fullscreen
          this.configureMobileNotFullscreen();
        }
      });

      this.configureMobileNotFullscreen();
    } else {
      this.addFullscreenButton();
    }
  }

  private configureMobileNotFullscreen() {
    // When on mobile, we don't want to accidentally drag when scrolling
    // Instead, users will drag when they enter fullscreen => so no dragging in map
    this.map.dragging.disable();
    this.map.on('click', () => this.tap());
    this.map.removeControl(this.fullscreenBtn);
  }

  private fullscreenBtn = L.control.fullscreen({
    position: 'topleft',
    // @ts-ignore
    title: {
      false: this.translate.instant('MAP.FULLSCREEN.ENTER'),
      true: this.translate.instant('MAP.FULLSCREEN.EXIT')
    }
  });

  private addFullscreenButton() {
    // add fullscreen control to the map
    this.map.addControl(this.fullscreenBtn);
  }

  /**
   * Actual tap on a mobile device
   */
  tap() {
    if (this.isFullScreenAllowed && !this.map.isFullscreen()) {
      this.map.toggleFullscreen();
    }
  }

  /**
   * Explicit exit of fullscreen (when navigating to a different page, from the map)
   */
  exitFullscreen() {
    if (this.map.isFullscreen()) {
      this.map.toggleFullscreen();
    }
  }

  // -------------------------------

  protected onMapReady(): void {

  }

  setViewPoint(point: LatLngExpression, zoom: number) {
    // FIXME would be cool to avoid this hack
    window.setTimeout(() => {
      try {
        this.map.invalidateSize(true);
        this.map.setView(point, zoom);
      } catch (e) {
        // ignore. Very ugly but prevents errors in console when user navigates out of the page before one second
      }
    }, 1000);
  }

  setViewPolygon(location: GeoJSON) {
    window.setTimeout(() => {
      try {
        this.map.invalidateSize(true);
        this.map.fitBounds(location.getBounds(), {padding: [5, 5]});
      } catch (e) {
        // ignore. Very ugly but prevents errors in console when user navigates out of the page before one second
      }
    }, 1000);
  }

  onDrawCreated($event: DrawEvents.Created) {
    this.drawnItems.addLayer($event.layer);
    // Only one can be created => disable create
    if (this.pointOptions) {
      this.toggleDrawCreatePoint(false);
    }
    if (this.polygonOptions) {
      this.toggleDrawCreatePolygon(false);
    }
    this.edited.emit([$event.layer]);
  }

  onDrawDeleted($event: DrawEvents.Deleted) {
    $event.layers.eachLayer(l => this.drawnItems.removeLayer(l));
    if (this.pointOptions) {
      this.toggleDrawCreatePoint(true);
    }
    if (this.polygonOptions) {
      this.toggleDrawCreatePolygon(true);
    }
  }

  onDrawReady(drawControl: Control.Draw) {
    this.drawControl = drawControl;
    this.refreshDrawControl();
  }

  private refreshDrawControl() {
    if (this.drawControl) {
      this.map?.removeControl(this.drawControl);
      this.drawControl.setDrawingOptions({
        polygon: this.currentPolygonOptions,
        marker: this.currentMarkerOptions
      });
      this.map?.addControl(this.drawControl);
    }
  }

  protected addEditablePolygon(location: GeoJSON) {
    this.drawnItems.addLayer(location.getLayers()[0]);
    this.toggleDrawCreatePolygon(false);
  }

  protected addEditablePoint(location: Marker) {
    this.drawnItems.addLayer(location);
    this.toggleDrawCreatePoint(false);
  }

  onDrawEdited($event: DrawEvents.Edited) {
    this.edited.emit($event.layers.getLayers());
  }
}
