// Inspired by https://stackoverflow.com/questions/40933619/how-to-redirect-to-a-certain-route-based-on-condition-in-angular-2
import {UntilDestroy} from '@ngneat/until-destroy';
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {IAuthService} from '@services/i-auth.service';
import {of} from 'rxjs';
import { DEFAULT_ANONYMOUS_PAGE, DEFAULT_APP_PAGE } from '@pages/tabs/tabs.page';
import { Capacitor } from '@capacitor/core';

@UntilDestroy()
@Injectable()
export class LoggedInGuard implements CanActivate {
  constructor(
    private auth: IAuthService,
    private router: Router
  ) {
  }

  canActivate() {
    if (this.auth.isLoggedIn) {
      return of(true);
    }
    // On mobile app, redirect to spots (don't show "about" page)
    const defaultPage = Capacitor.isNativePlatform() ? DEFAULT_APP_PAGE : DEFAULT_ANONYMOUS_PAGE;
    return this.router.navigate([defaultPage.path]);
  }
}
